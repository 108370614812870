import request from "@/api/service";

const api = {
   page: '/event/enterpriseWechat/ewUser/getPage',
   list: '/event/enterpriseWechat/ewDepartment/getList',
   sync: '/event/enterpriseWechat/ewUser/synchDepartmentAndUser'
}

export function getPage(query, params) {
   return request({
      url: api.page + query,
      method: 'POST',
      data: params
   })
}

export function getList(params = {}) {
   return request({
      url: api.list,
      method: 'POST',
      data: params
   })
}

export function syncDepartmentAndUser(params) {
   return request({
      url: api.sync,
      method: 'POST',
      data: params
   })
}
