<template>
   <div class="cont">
      <Breadcrumb :crumbs="crumbs"/>
      <div class="content-box">
         <el-row class="search-box">
            <el-row class="search-row">
               <el-row class="search-item">
                  <label>员工名称：</label>
                  <el-input v-model="params.userName" placeholder="员工名称" clearable></el-input>
               </el-row>
               <el-row class="search-item">
                  <label>手机号：</label>
                  <el-input v-model="params.mobile" placeholder="手机号" clearable></el-input>
               </el-row>
               <el-row class="search-item">
                  <label>所属部门：</label>
                  <el-select v-model="params.departmentName" @focus="getDepartmentList">
                     <el-option v-for="item in departmentOpt" :key="item.id" :label="item.name" :value="item.id"></el-option>
                  </el-select>
               </el-row>
               <el-button class="bg-gradient" type="primary" icon="el-icon-search" @click="queryStaffList(true)">搜索</el-button>
               <el-button icon="el-icon-refresh-right" @click="queryStaffList(false)" plain>重置</el-button>
            </el-row>
         </el-row>
         <el-row class="table-box">
           <el-row type="flex" justify="space-between">
             <el-row></el-row>
             <el-row class="right-btn">
               <el-button @click="handleSync" class="bg-gradient" type="primary">同步部门和员工</el-button>
             </el-row>
           </el-row>
           <el-table
                v-loading="loading"
                :data="tableData"
                style="width: 100%"
                :stripe="true"
                border fit>
               <el-table-column label="序号" width="60">
                  <template slot-scope="scope">{{scope.$index + 1}}</template>
               </el-table-column>
               <el-table-column prop="userName" label="员工名称" min-width="70"></el-table-column>
               <el-table-column prop="mobile" label="手机号" min-width="70"></el-table-column>
               <el-table-column label="状态" min-width="70">
                 <template slot-scope="scope">{{ scope.row.status | filterStatus(that) }}</template>
               </el-table-column>
               <el-table-column prop="departmentName" label="所属部门"></el-table-column>
               <el-table-column prop="customerCount" label="客户数量"></el-table-column>
            </el-table>
           <!-- 分页 -->
           <pagination :total="total" :page-size="limit" @handleSizeChangeSub="changePagNum" @handleCurrentChangeSub="changeCurrPage"/>
         </el-row>
      </div>
   </div>
</template>
<script>
import { getPage, getList, syncDepartmentAndUser } from '@/api/data/wechat/staff'
import { mapState } from 'vuex'
import { getDict } from "@/common/js/common";
export default {
  name: 'liveCode',
  data() {
    return {
      crumbs: new Map([
        ['数据中台', ''], ['企业微信', ''], ['员工管理', '']
      ]),
      that: this,
      tableData: [],
      departmentOpt: [],
      loading: false,
      total: 0,
      limit: 0,
      page: 1,
      params: {
        userName: '',
        mobile: '',
        departmentName: ''
      },
    }
  },
  computed: { ...mapState(['hotelInfo', 'dictData']) },
  mounted() {
    this.limit = sessionStorage.getItem('pageSize') * 1
    getDict('employee-status')
    this.getStaffList()
  },
  methods: {
    // 获取员工列表
    getStaffList() {
      const query = `?limit=${ this.limit }&page=${ this.page }`,
          params = { hotelId: this.hotelInfo.id, companyId: this.hotelInfo.storeId }
      getPage(query, params).then(({ success, total, records }) => {
        if (!success) return
        this.total = total
        this.tableData = records
      })
    },
    // 获取部门列表
    getDepartmentList() {
      if (this.departmentOpt.length > 0) return
      const params = { hotelId: this.hotelInfo.id, companyId: this.hotelInfo.storeId }
      getList(params).then(({ success, records }) => {
        if (!success) return
        this.departmentOpt = records.map(item => ({ id: item.id,name: item.name }))
      })
    },
    // 查询员工列表
    queryStaffList(bool) {
      this.page = 1
      if (bool) return this.getStaffList()
    },
    // 改变每页数
    changePagNum(num) {
      this.limit = num
      this.getStaffList()
    },
    // 改变当前页
    changeCurrPage(num) {
      this.page = num
      this.getStaffList()
    },
    // 创建活动
    createActivity() {
      this.$router.push({ path: '/enterprise_wechat/live_code/create' })
    },
    // 处理同步
    handleSync() {
      const params = { hotelId: this.hotelInfo.id, companyId: this.hotelInfo.storeId }
      syncDepartmentAndUser(params).then(({ success }) => {
        if (!success) return
        this.getStaffList()
        this.$message({ message: '同步成功！', type: 'success' })
      })
    }
  },
  watch: {
    hotelInfo(newVal, oldVal) {
      if (oldVal && newVal.id !== oldVal.id) {
        this.getStaffList()
        this.getDepartmentList()
      }
    }
  },
  filters: {
    filterStatus(val, that) {
      if (val && that.dictData['employee-status']) return that.dictData['employee-status'][val] || val
    }
  }
}
</script>
<style lang="scss" scoped></style>
